<template>
    <div class="stage">
        <div class="stage__text">
            <label class="stage__label">{{stageLabel}}</label>
            <h1 v-html="title"/>
            <button class="stage__button" v-if="signInButton" @click="$emit('signInClicked')">{{signInButtonText}}</button>
            <div v-if="downloads && downloads.links.length" class="stage__button-sektion">
                <link-button :anchor="true" @clicked="$emit('scrollToDownloads')">{{$i18n.locale === 'de' ? 'Zu den Weiterführenden Links' : 'Scroll to further information'}}</link-button>
                <link-button v-if="downloads.allProjectDownloads" :link="downloads.allProjectDownloads.url">{{downloads.allProjectDownloads.title}}</link-button>
            </div>
        </div>
        <div class="stage__image-wrapper" :class="{'stage__image-wrapper--big': big}">
            <img v-if="image" class="stage__image" :src="image" :alt="$route.name"/>
            <div v-if="content" class="stage__text">
                <div class="stage__job-details">
                    <h3>{{$i18n.locale === 'en' ? 'Facts' : 'Fakten'}}</h3>
                    <div
                        v-for="(fact, i) in content"
                        :key="`fact-${i}`"
                        class="stage_fact"
                    >
                        {{fact}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import linkButton from './link-button.vue'
export default {
  components: { linkButton },
    name: 'Stage',
    props: {
        title: {
            type: String,
            required: true,
        },
        stageLabel: {
            type: String,
            default: '',
        },
        image: {
            type: String,
        },
        content: {
            type: Object,
            default: () => {},
        },
        big: {
            type: Boolean,
            default: false,
        },
        signInButton: {
            type: Boolean,
            default: false,
        },
        downloads: {
            type: Object,
            default: () => {},
        },
        _signInButtonText: {
            type: String,
        },
    },
    data() {
        return {
            signInButtonText: this._signInButtonText || (this.$i18n.locale === 'de' ? 'Anmelden' : 'Sign in'),
        }
    },
    
}
</script>
<style lang="scss" scoped>
.stage {
    max-width: 1140px;
    display: flex;
    flex-wrap: wrap-reverse;
    margin: auto;
    overflow: hidden;
    @media (min-width: 1024px) {
        height: 442px;
        flex-wrap: nowrap;
    }
    &__text {
        display: flex;
        flex: 1;
        flex-direction: column;
        color: #182952;
        padding: 3.2rem .8rem;
        max-width: 698px;
        @media (min-width: 1140px) {
            padding: 60px 2.6rem 60px 0;
        }
        h1 {
            font-size: 30px;
            line-height: 1.2;
            font-family: 'neue-plak';
            font-weight: 700;
            margin: 0 0 1.6rem;
            // hyphens: auto;
            @media (min-width: 1024px) {
                font-size: 56px;
                letter-spacing: .1px;
                margin: 0 0 50px;
            }
        }
    }
    &__label {
        font-size: 18px;
        font-weight: 700;
    }
    &__image-wrapper {
        display: none;
        flex: 1;
        max-width: 442px;
        min-width: 320px;
        width: 100%;
        max-height: 320px;
        background-color: #e8edf8;
        &--big {
            max-width: 650px;
            @media (min-width: 1140px) {
                min-width: 650px;
            }
        }
        @media (min-width: 1024px) {
            max-height: 100%;
            display: flex;
        }
    }
    &__image {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
    &__anchors {
        font-family:"freight-text-pro";
        font-style: italic;
        font-weight: bold;
        font-size: 18px;
        a {
            color: #ffffff;
            text-decoration: none;
            &:last-child {
                margin-left: 50px;
            }
        }
    }
    &__job-details {
        padding: 0 65px;
        font-size: 18px;
        line-height: 1.5;
    }
    &__button {
        margin: 0 auto 0 0;
        text-decoration: none;
        font-family: inherit;
        font-weight: bold;
        font-size: 16px;
        background-color: #0c3818;
        border: none;
        outline: none;
        border-radius: 20px;
        color: #ffffff;
        padding: 8px 24px;
        transition: all 300ms;
        cursor: pointer;
        &:hover {
            background-color: #5e6534;
        }
    }
    &__button-sektion {
        display: flex;
        gap: 1rem;
        justify-content: flex-start;
        flex-wrap: wrap;

        &>* {
            flex: 0 0 auto;
            margin: 0;
        }

        &>*:last-child {
            background-color: #ffffff;
            color: #0c3818;
            border: 2px solid #0c3818;
        }
    }
}
</style>